/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="home">
    <div class="part clear">
      <div>
        <div class="horizontal">
          <div class="eventListBar">
            <ul class="eventList">
              <Events v-if="events" :events="events" :language="language"></Events>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Events from "./components/Events.vue";
import { ref, onMounted } from "vue";
import Nprogress from "nprogress";
import 'nprogress/nprogress.css';
import "iframe-resizer/js/iframeResizer.contentWindow.min.js"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "App",
  components: {
    Events,
  },
  setup() {
    const loading = ref(true);
    const error = ref(null);
    const rows = ref(null);
    const metaData = ref(null);
    const events = ref(null);
    const language = ref(null);


//hier moet uit de integratie URL de /nl of /en extentie worden gehaald. 
    const setLanguage = (Sitelanguage) => {
      language.value = Sitelanguage;
    };
    
    const createEvents = (rows) => {
      events.value = rows.map((row) => {
        return {
          Type_item: row.Type_cursus,
          Type_item_code: row.Type_cursus_code,
          Inschrijfbaar: row.Inschrijfbaar,
          Code: row.Evenement_ID,
          Omschrijving:
            language.value == "nl"
              ? row.Cursus_Nederlands
              : row.Cursus_Engels
              ? row.Cursus_Engels
              : row.Cursus_Nederlands,
          Datum: row.Datum,
          Locatie_omschrijving: row.Locatie_Nederlands,
          Begintijd: row.Begintijd,
          Eindtijd: row.Eindtijd,
          Beschikbaar: row.Beschikbaar,
          Type_inschrijving:
          language.value == "nl" ? row.Omschrijving_Nederlands : row.Omschrijving_Engels,
          Afbeelding: row.Afbeelding,
          Taal: row.Taal,
          Opmerking: language.value == "nl" ? row.Opmerking_nederlands : row.Opmerking_engels,
          Sitetaal: language.value,
          Bestandsnaam: row.Bestandsnaam.toLowerCase()
        };
      });
    };

    const fetchEvents = () => {
      loading.value = true;
      Nprogress.start();

      return fetch(
        `https://node01.flinker.online/tailwind/cursus${window.location.search}`,
        {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
        }
      )
        .then((res) => {
          // a non-200 response code
          if (!res.ok) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then((json) => {
          // set the response data
          setLanguage(json.Sitelanguage);
          createEvents(json.rows);
        })
        .catch((err) => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then((json) => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
          Nprogress.done();
        });
    };

    onMounted(() => {
      fetchEvents();
    });

    return { rows, metaData, events, error, language };
  },
};
</script>
<style>
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
   url(./fonts/Roboto-Regular.ttf) format("truetype");
}

.home {
  padding-bottom: 50px;
}
body {
  font-family: Roboto;
}

</style>
