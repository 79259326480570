<template>
  <div class="bg-white rounded-xl shadow-md overflow-hidden">
    <div class="md:flex">
      <div class="md:shrink-0">
        <img
          :src="'https://staticfilesontwikkeling.blob.core.windows.net/$web/' + event.Bestandsnaam"
          alt="Photo placeholder"
          class="h-56 w-full object-cover md:h-full md:w-40"
        />
      </div>
      <div class="p-6 pb-2 flex flex-col justify-between">
        <div class="">
          <div class="uppercase tracking-wide text-sm text-tpsoc-purple font-semibold">
            {{ event.Type_inschrijving }}
          </div>
          <p class="block mt-1 text-lg leading-tight font-medium text-tpsoc-purple">
            {{ event.Omschrijving }}
          </p>
          <p class="mt-2 text-slate-500">
            {{ event.Opmerking }}
          </p>
        </div>

        <div class="content-bottom">
          <div class="mt-4 text-slate-500 text-sm">
            <div v-if="beschikbaarheid !== 'vol'">
              <a :href="url" @click="scrollToTop">
                <button
                  class="mt-4 text-white hover:bg-tpsoc-gold hover:opacity-50 focus:ring-4 focus:ring-tpsoc-purple focus:opacity-20 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 dark:bg-tpsoc-purple dark:hover:bg-tpsoc-gold hover:opacity-50 dark:focus:ring-tpsoc-purple dark:focus:opacity-20"
                  :class="{
                    'bg-orange-300': beschikbaarheid == 'bijnavol',
                    'bg-tpsoc-gold': beschikbaarheid == 'beschikbaar',
                    'bg-red-300': beschikbaarheid == 'vol',
                  }"
                  :disabled="beschikbaarheid == 'vol'"
                >
                  <div>{{ translate("buttonText") }}</div>
                </button>
              </a>
            </div>
            <div v-else>
              <button class="mt-4 text-white bg-red-300 opacity-50 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2" disabled>
                <div>{{ translate("buttonText") }}</div>
              </button>
            </div>

            <div class="beschikbaarheid" v-if="event.Beschikbaar < 4">
              {{ translate("beschikbaarheid") }}
            </div>
          </div>

          <p class="mt-4 text-slate-500 text-sm">
            {{ event.Datum }} | {{ event.Begintijd }} - {{ event.Eindtijd }} |
            {{ event.Locatie_omschrijving }} | {{ translate("taal") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";

export default {
  name: "eventItem",
  components: {},
  props: ["event", "language"],
  setup(props) {
    const beschikbaarheid = computed(() => {
      let beschikbaar = "beschikbaar";

      if (props.event.Beschikbaar == 0) beschikbaar = "vol";
      if (props.event.Beschikbaar > 0 && props.event.Beschikbaar < 3) beschikbaar = "bijnavol";
      if (props.event.Beschikbaar > 2) beschikbaar = "beschikbaar";

      return beschikbaar;
    });

    const url = computed(() => {
      return beschikbaarheid.value == "vol"
        ? undefined
        : `https://formulier.flinker.online/?init_config=https://node01.flinker.online/tpsoc/formulier&code=${props.event.Code}&taal=${props.language}`;
    });
    const translate = (veldnaam) => {
      const language = props.language;

      switch (veldnaam) {
        case "beschikbaarheid":
          return language == "nl"
            ? props.event.Beschikbaar > 1
              ? `Nog ${props.event.Beschikbaar} plaatsen beschikbaar`
              : props.event.Beschikbaar == 1
              ? `Nog ${props.event.Beschikbaar} plaats beschikbaar`
              : `Geen plaatsen beschikbaar`
            : props.event.Beschikbaar > 1
            ? `Only ${props.event.Beschikbaar} seats available`
            : props.event.Beschikbaar == 1
            ? `Only ${props.event.Beschikbaar} seat available`
            : `No seats available`;

        case "buttonText":
          switch (beschikbaarheid.value) {
            case "vol":
              return language == "nl" ? "Vol" : "Booked";

            default:
              return language == "nl" ? "Inschrijven" : "Register";
          }
        case "taal":
          if (props.language == "nl") {
            if (props.event.Taal == "Nederlands") {
              return props.event.Taal;
            } else if (props.event.Taal == "Engels") {
              return props.event.Taal;
            }
          } else {
            if (props.event.Taal == "Nederlands") {
              return "Dutch";
            } else if (props.event.Taal == "Engels") {
              return "English";
            }
          }
          break;

        default:
          break;
      }
    };

    const scrollToTop = () => {
      if ("parentIFrame" in window) {
        window.parentIFrame.scrollTo(0, 300);
      }
      scroll(0, 0);
    };

    return {
      url,
      translate,
      beschikbaarheid,
      scrollToTop,
    };
  },
};
</script>
<style>
.beschikbaarheid {
  display: inline;
}
</style>
