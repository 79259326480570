<template>
  <div class="container mx-auto" :class="{ 'mt-5' : hideHeader}">
    <div class="my-5 md:my-10 flex flex-col md:flex-row gap-0 md:gap-5" v-if="!hideHeader">
      <div class="">
        <div class="xl:w-96">
          <div class="input-group relative flex flex-wrap items-stretch w-full mb-4">
            <input
              v-model="search"
              type="search"
              class="form-control relative flex-auto min-w-0 block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-l transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-tpsoc-purple focus:outline-none"
              placeholder="Search..."
            />
            <button
              class="btn inline-block px-6 py-2.5 bg-tpsoc-purple text-white font-medium text-xs leading-tight uppercase rounded-r shadow-md hover:bg-tpsoc-purple hover:shadow-lg focus:bg-tpsoc-purple focus:shadow-lg focus:outline-none focus:ring-0 active:bg-tpsoc-purple active:shadow-lg transition duration-150 ease-in-out flex items-center"
              type="button"
              disabled="true"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="search"
                class="w-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="">
        <button
          class="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-tpsoc-lightPurple dark:text-tpsoc-purple"
          :class="{ 'bg-tpsoc-lightPurple text-tpsoc-purple bg-white-500': !nederlands, 'bg-tpsoc-purple text-white': nederlands }"
          v-on:click="toggleNederlands"
          type="button"
        >
          Nederlands
        </button>
        <button
          class="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-tpsoc-lightPurple dark:text-tpsoc-purple"
          :class="{ 'bg-tpsoc-lightPurple text-tpsoc-purple  bg-white-500': !engels, 'bg-tpsoc-purple text-white': engels }"
          v-on:click="toggleEngels"
          type="button"
        >
          English
        </button>
      </div>
    </div>
    <div class="">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div class="space-y-10">
          <div v-for="event in highlightCursussen" :key="event.code">
            <Cards :event="event" :key="event.code" :language="language"></Cards>
          </div>
        </div>
        <div class="space-y-10">
          <div v-for="event in reguliereCursussen" :key="event.code">
            <Cards :event="event" :key="event.code" :language="language"></Cards>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import Cards from "./Cards.vue";

export default {
  name: "eventList",
  components: { Cards },
  props: ["events", "language"],
  setup(props) {
    const hideHeader = ref(false);
    const nederlands = ref(false);
    const engels = ref(false);
    const url = `https://formulier.flinker.online/?init_config=https://node3.staging.flinker.online/tpsoc/formulier&code=`;
    const search = ref("");
    
    // eslint-disable-next-line vue/no-setup-props-destructure

    //toggle Basic filter
    const toggleNederlands = () => {
      return (nederlands.value = !nederlands.value);
    };

    //toggle Expert filter
    const toggleEngels = () => {
      return (engels.value = !engels.value);
    };

    //getQueryParamsParentWindow
    const getQueryParameters = () => {
      const queryStringKeyValue = window.location.search.replace("?", "").split("&");
      return queryStringKeyValue.reduce((acc, curr) => {
        const [key, value] = curr.split("=");
        return {
          ...acc,
          [key]: decodeURI(value),
        };
      }, {});
    };    

    const includes = (str, substr) => {
      try{
        return str.toLowerCase().indexOf(substr.toLowerCase()) > -1;
      } catch {
        return false;
      }      
    };

    // create array voor "Prosci Certificering" type. Filter obv snelfilters + zoekfunctie.
    const highlightCursussen = computed(() => {
      return props.events.filter(
        (event) =>
          event.Type_item_code == "01" &&
            (
              (nederlands.value ? includes(event.Taal, "nederlands") : true) ||
              (nederlands.value ? includes(event.Taal, "dutch"): true)
            ) &&
            (
              (engels.value ? includes(event.Taal, "engels") : true) ||
              (engels.value ? includes(event.Taal, "english") : true)
            ) 
            &&
            (
              (search.value == "" ? true : includes(event.Opmerking, search.value)) 
              || (search.value == "" ? true : includes(event.Taal, search.value)) 
              || (search.value == "" ? true : includes(event.Locatie_omschrijving, search.value))
              || (search.value == "" ? true : includes(event.Omschrijving, search.value))
              || (search.value == "" ? true : includes(event.Type_item_omschrijving, search.value))
              || (search.value == "" ? true : event.Code.toString() === search.value )
            )
      );
    });

    //create array voor niet prosci certificering type. Filter obv snelfilters + zoekfunctie.
    const reguliereCursussen = computed(() => {
      return props.events.filter(
        (event) =>
          event.Type_item_code != "01" &&
          (
              (nederlands.value ? includes(event.Taal, "nederlands") : true) ||
              (nederlands.value ? includes(event.Taal, "dutch"): true)
            ) &&
            (
              (engels.value ? includes(event.Taal, "engels") : true) ||
              (engels.value ? includes(event.Taal, "english") : true)
            ) 
            &&
            (
              (search.value == "" ? true : includes(event.Opmerking, search.value)) 
              || (search.value == "" ? true : includes(event.Taal, search.value)) 
              || (search.value == "" ? true : includes(event.Locatie_omschrijving, search.value))
              || (search.value == "" ? true : includes(event.Omschrijving, search.value))
              || (search.value == "" ? true : includes(event.Type_item_omschrijving, search.value))
              || (search.value == "" ? true : event.Code.toString() === search.value )
            )
      );
    });

    onMounted(() => {
      search.value = getQueryParameters().qfilter || ""
      hideHeader.value = getQueryParameters().hideHeader || 0
    });


    return {
      engels,
      nederlands,
      url,
      toggleEngels,
      toggleNederlands,
      highlightCursussen,
      search,
      reguliereCursussen,
      hideHeader
    };
  },
};
</script>

<style scoped></style>
